<template>
  <dialog-form-list
      :config="config"
      :formData="formData"
      @cancel="cancel"
      @confirm="confirm"
  >
    <template v-slot:headers>
      <div v-for="(item, index) in formData.headers" class="header-item" :key="index">
        <el-input v-model="item.key" :placeholder="'key'" />
        <el-input v-model="item.value" :placeholder="'value'" />
        <i v-if="cmd !== 'view'" class="el-icon-plus" @click.stop="addHeader"/>
        <i v-if="cmd !== 'view'" class="el-icon-delete" @click.stop="delHeader(index)"/>
      </div>
    </template>
    <template v-slot:requestValues>
      <div v-for="(item, index) in formData.requestValues" class="header-item" :key="index">
        <el-input v-model="item.key" :placeholder="'key'" :readonly="true"/>
        <el-select v-model="item.dataFormType" placeholder="请选择">
          <el-option
              v-for="configItem in $dict.dataFormTypeEnum()"
              :key="configItem.value"
              :label="configItem.label"
              :value="configItem.value">
          </el-option>
        </el-select>
      </div>
    </template>
  </dialog-form-list>
</template>

<script>
import {netRequestType} from "@/dict";

export default {
  props: {
    tableRow: {
      // 当前选中的表格行数据
      type: Object,
      default: () => null,
    },
  },
  data() {
    let _that = this
    return {
      cmd: this.$store.state.cmd,
      formData: {
        headers: [{key:null, value:null}],
      },
      config: [
        // lang:名称
        {
          label: this.$t('companyApiManage.name'),
          prop: 'name',
          rules: {
            noNull: true,
          },
        },
        {
          tag: 'el-select',
          label: this.$t('companyApiManage.ruleType'),
          prop: 'ruleType',
          rules: {
            noNull: true,
          },
          tagProps: {
            options: this.$dict.apiRuleType(),
          },
          tagEvents: {
            change(val) {
              if(val === 'READ') {
                _that.config[5].rules.noNull = false
                _that.config[7].rules.noNull = true
              }
              if(val === 'WRITE') {
                _that.config[5].rules.noNull = true
                _that.config[7].rules.noNull = false
              }
            }
          }
        },
        {
          label: this.$t('companyApiManage.url'),
          prop: 'url',
          rules: {
            noNull: true,
            // type: 'url',
          },

        },
        {
          tag: 'el-select',
          label: this.$t('companyApiManage.method'),
          prop: 'netRequestType',
          rules: {
            noNull: true,
          },
          tagProps: {
            options: this.$dict.netRequestType(),
          },
        },
        {
          tag: 'slot-content',
          label: this.$t('companyApiManage.header'),
          prop: 'headers',
          rules: {
          },
        },
        {
          label: this.$t('companyApiManage.request'),
          prop: 'requestBody',
          rules: {
          },
          tagProps: {
            type: 'textarea',
            rows: 8,
          },
        },
        {
          tag: 'slot-content',
          label: this.$t('platformApiManage.requestValues'),
          prop: 'requestValues',
          hidden: true,
          rules: {
          },
        },
        {
          label: this.$t('companyApiManage.response'),
          prop: 'responseBody',
          rules: {
          },
          tagProps: {
            type: 'textarea',
            rows: 8,
          },
        },
      ],
    }
  },
  watch: {
    'formData.requestBody'(val) {
      this.formData.requestValues = this.getRequestStr(val)
      console.log('this.formData.requestValue', this.formData.requestValues)
      if (this.formData.requestValues.length > 0) {
        this.config[6].hidden = false
      } else {
        this.config[6].hidden = true
      }
    }
  },
  mounted() {
    this.setDefaultValue()
  },
  methods: {
    getRequestStr(requestStr) {
      const list = []
      if(requestStr) {
        const jsonStr = JSON.parse(requestStr)
        if(typeof jsonStr === 'object'){
          const keys = Object.keys(jsonStr)
          keys.forEach(item => {
            console.log('jsonStr[item]', jsonStr[item])
            if(typeof jsonStr[item] === 'string' && jsonStr[item].indexOf('${') > -1) {
              const key1 = jsonStr[item].replace('${', '')
              const key2 = key1.replace('}', '')
              console.log('key1 key2',key1, key2)
              let dataFormType = 'BOOLEAN_FORM'
              let oldData = this.$util.copyData(this.formData.requestValues)
              if(typeof oldData === 'string') {
                oldData = JSON.parse(oldData)
              }
              console.log(oldData)
              if (oldData.length > 0) {
                const data = oldData.filter(item1 => item1.key === key2)
                if (data.length > 0) {
                  dataFormType = data[0].dataFormType
                }
              }
              let tmp = {
                key: key2,
                dataFormType
              }
              list.push(tmp)
            }
          })
        }
      }
      return list
    },
    // 设置默认值
    setDefaultValue() {
      this.$nextTick(() => {
        //默认值
        if (this.cmd !== 'add') {
          this.$util.setDefaultValue(this.formData, this.tableRow)
          if(this.tableRow.headerJson) {
            this.formData.headers = JSON.parse(this.tableRow.headerJson)
          } else {
            this.formData.headers = [{key:null, value:null}]
          }
          if(this.formData.ruleType === 'READ') {
            this.config[5].rules.noNull = false
            this.config[7].rules.noNull = true
          }
          if(this.formData.ruleType === 'WRITE') {
            this.config[5].rules.noNull = true
            this.config[7].rules.noNull = false
          }
          if(this.tableRow.requestValue) {
            this.formData.requestValues = JSON.parse(this.tableRow.requestValue)
          }
        }
      })
    },
    // 取消
    cancel() {
      this.$emit('closePage', 'cancel')
    },
    // 确定
    confirm() {
      const formData = this.$util.copyData(this.formData)
      formData.scope = 'COMPANY'
      formData.headers = formData.headers.filter(item => item.key)
      if(formData.headers.length === 0) {
        formData.headers = null
      }
      if (formData.requestValues === "") {
        formData.requestValues = []
      }
      switch (this.cmd) {
        case 'add':
          this.addMethod(formData)
          break
        case 'edit':
          this.editMethod(formData)
          break
        default: // do something
      }
    },
    // 新增
    addMethod(formData) {
      this.$ajax({
        url: '/' + this.$util.getLocalStorage('apiprefix') + this.$api.apiAdd,
        data: formData,
      }).then(() => {
        // lang:新增成功
        this.$element.showMsg(this.$t('base.addSuccess'), 'success')
        this.$emit('closePage', 'add')
      })
    },
    // 修改
    editMethod(formData) {
      const tableRow = this.$util.copyData(this.tableRow)
      Object.assign(tableRow, formData)
      this.$ajax({
        url: '/' + this.$util.getLocalStorage('apiprefix') + this.$api.apiUpdate,
        data: tableRow,
      }).then(() => {
        // lang:修改成功
        this.$element.showMsg(this.$t('base.editSuccess'), 'success')
        this.$emit('closePage', 'edit')
      })
    },
    //添加header
    addHeader() {
      let defaultData = {
        key: null,
        value: null,
      }
      this.formData.headers.push(defaultData)
    },
    // 删除header
    delHeader(index) {
      if(this.formData.headers.length === 1) {
        return
      } else {
        this.formData.headers.splice(index, 1)
      }
    }
  },
}
</script>

<style lang="scss" scoped>
@import '@/style/theme.scss';
.header-item {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: 10px;
  &:last-child {
    margin-bottom: 0;
  }
  .el-input {
    margin-right: 10px;
  }
  i {
    margin-right: 10px;
    cursor: pointer;
    color: $--color-primary;
    &:hover {
      font-weight: bold;
    }
  }
}
</style>
